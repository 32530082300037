import { graphql } from 'gatsby'
import React from 'react'

import { LayoutTailwindDefault } from '@/layouts/layout-tailwind-default'
import { useProduct } from '@/hooks/use-product'
import { classNames } from '@/utils/class-names'
import { ProductDetails } from '@/components/tailwind/product-details'
import { ProductCarousel } from '@/components/tailwind/product-carousel'
import { ProductFaq } from '@/components/tailwind/product-faq'
import { ProductSection } from '@/components/tailwind/product-section'
import { ProductFeatureRow } from '@/components/tailwind/product-feature-row'
import { ProductForm } from '@/components/tailwind/product-form'
import { ProductReviews } from '@/components/tailwind/product-reviews'
import { ProductTabs } from '@/components/tailwind/product-tabs'
import { ProductTestimonials } from '@/components/tailwind/product-testimonials'

export const query = graphql`
  query SmartWalletProductQuery {
    product: shopifyProduct(handle: { eq: "airtag-smart-wallet" }) {
      ...ProductSection
    }
    suggestions: allShopifyProduct(limit: 3) {
      nodes {
        ...ProductCard
      }
    }
  }
`

const reviews = {
  average: 4,
  totalCount: 1624,
  counts: [
    { rating: 5, count: 1019 },
    { rating: 4, count: 162 },
    { rating: 3, count: 97 },
    { rating: 2, count: 199 },
    { rating: 1, count: 147 },
  ],
  featured: [
    {
      id: 1,
      rating: 5,
      content: `
          This is the bag of my dreams. I took it on my last vacation and was able to fit an absurd amount of snacks for the many long and hungry flights.
        `,
      author: 'Emily Selman',
      avatarSrc:
        'https://images.unsplash.com/photo-1502685104226-ee32379fefbe?ixlib=rb-=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=8&w=256&h=256&q=80',
    },
    {
      author: 'Boris',
      thumb: 'https://images.loox.io/uploads/2021/11/26/V1QjgcWqdj.jpg',
      rating: 4,
      content: `got a few of these for christmas gifts, you know i had to treat myself too. pretty awesome.`,
    },
    {
      author: 'Jesse U.',
      thumb: 'https://images.loox.io/uploads/2021/11/26/E1auKZ5uo.jpg',
      rating: 5,
      content: `just got this in and my only regret was not buying it sooner!`,
    },
    {
      author: 'Ken T.',
      thumb: 'https://images.loox.io/uploads/2021/11/26/EJpkFb5ds.jpg',
      rating: 4,
      content: `love the minimalist feel and it looks good on my desk.`,
    },
    {
      author: 'Larry',
      thumb: 'https://images.loox.io/uploads/2021/11/26/41k4_ZqOs.jpg',
      rating: 4,
      content: `love the minimalist feel and it looks good on my desk.`,
    },
    {
      author: 'Justin R.',
      thumb: 'https://images.loox.io/uploads/2021/11/26/V1QjgcWqdj.jpg',
      rating: 4,
      content: `I like this wallet a lot more than my last one and the air tag is super convenient. Most likely gonna get the whole fam one for christmas.`,
    },
    {
      author: 'Leo',
      thumb: 'https://images.loox.io/uploads/2021/11/26/NJH0vbq_o.jpg',
      rating: 3,
      content: `The carbon fiber is dope, wish it had more space tho`,
    },
    {
      author: 'Jackson S.',
      thumb: 'https://images.loox.io/uploads/2021/10/18/4kvy0sUHi.jpg',
      rating: 5,
      content: `I don't usually write reviews but this thing saved me! Was lookin for my wallet and forgot I could track it. Pulled it up and it was outside on my buddys truck getting ready to take off! This wallet is awesome man.`,
    },
    {
      author: 'Hunter H.',
      thumb: 'https://images.loox.io/uploads/2021/10/18/4J2p2sUSo.jpg',
      rating: 5,
      content: `Just looking at this thing is satisfying, its been a week and I still can't get over this thing. 10/10 Would recommend.`,
    },
    {
      author: 'Cam',
      thumb: null,
      rating: 4,
      content: `Clutch birthday gift`,
    },
    {
      author: 'Hb H.',
      thumb: null,
      rating: 5,
      content: `Gh`,
    },
    {
      author: 'Cool wallet',
      thumb: null,
      rating: 5,
      content: `pretty dope. USPS lost my package tho`,
    },
    // More reviews...
  ],
}

export const SmartWallet = React.memo(
  ({ data: { product, staticImages } }: any) => {
    const { images } = useProduct(product)

    return (
      <LayoutTailwindDefault
        className={`bg-gradient-to-b from-transparent to-concrete-500`}
      >
        <ProductSection
          product={product}
          className={`bg-gradient-to-b from-transparent to-concrete-500`}
        />
        <section className={`bg-concrete-500`}>
          {[
            {
              title: 'Genuine Leather',
              subTitle: 'Craftsmanship & Materials',
              description: [
                `Reducing your bulk is in our DNA.`,
                `Our wallets are the slimmest to date and have more space than ever.`,
                `You can seamlessly carry up to 12 cards & 20 Bills at a fraction of the size of a traditional wallet.`,
              ],
              imageName: `air-wallet-promo-1.jpg`,
            },
            {
              title: 'Minimalist, Light and Elegant Design',
              subTitle: 'Less bulk. More storage.',
              description: [
                `The wallet is Slim, sleek and has a modular design that will be the perfect touch for your everyday
            carry essentials since it can perfectly fit discreetly in your front pocket.`,
                `It's time to ditch that bulky
            back breaking wallet!`,
              ],
              imageName: `air-wallet-promo-2.jpg`,
              ctaText: `Buy now`,
              ctaLink: `#`,
            },
            {
              title: 'Anti RFID Technology',
              subTitle: 'Peace of mind.',
              description: [
                `We incorporated the most advanced technology for wallets that you have ever seen.`,
                `The aluminum compartment that we have built inside the wallet secures the data that your cards have, making it impossible for thieves to steal your credit card personal information with a wireless device.`,
              ],
              imageName: `air-wallet-promo-3.jpg`,
              ctaText: `Buy now`,
              ctaLink: `#`,
            },
            {
              title: 'Redefining wallet',
              subTitle: 'Less bulk. More storage.',
              description: [
                `Hand-crafted layers, meticulously sewn to each other.`,
                `Perfect stitching to weave the wallet together.`,
                `As this leather ages, that shine is replaced with a matte finish, and the leather
        becomes softer with use.`,
              ],
              imageName: `air-wallet-promo-4.jpg`,
              ctaText: `Buy now`,
              ctaLink: `#`,
            },
          ].map((props, index) => (
            <ProductFeatureRow key={index} index={index} {...props} />
          ))}
        </section>
        {/*
      <ProductFeatures staticImages={staticImages} />

      <ProductTabs staticImages={staticImages} />
      <ProductTestimonials />
      <ProductFaq />

      <section aria-labelledby='reviews-heading' className='bg-black'>
        <div className={`container mx-auto`}>
          <ProductReviews
            title={`Technical Specifications`}
            content={` The walnut wood card tray is precision milled to perfectly fit a
            stack of Focus cards. The powder coated steel divider separates
            active cards from new ones, or can be used to archive important task
            lists.`}
          />
        </div>
      </section>
        */}
      </LayoutTailwindDefault>
    )
  },
  (prev, next) => {
    return true

    /*
  return true if passing nextProps to render would return
  the same result as passing prevProps to render,
  otherwise return fals
  */
  },
)

export default SmartWallet
